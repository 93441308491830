<template>
  <div class="smartDTContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          人事人才培训服务
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="ptDesc center">
        <div class="ptDescContent">
          <div class="ptDescLeft">
            <img src="@/assets/product/productRsrcpx/rsrcDesc.png" alt="" style="width:450px;height:100%;border-radius: 10px 0 0 10px;">
            <div class="ptDescLeftMask"></div>
          </div>
          <div class="ptDescRigth">
            新时代高校人事人才管理能力提升专题培训班提供了一个高水平高层次的分享交流平台，旨在服务于高等学校、科研院所的人事工作者，为深入学习党的二十大精神，贯彻落实新时代人才工作的新理念新战略新举措，进一步加强新时代高校人事工作者队伍建设，提升人事工作者的政治理论素养和专业研究能力，推动高校人事人才工作高质量发展。培训将助力参与者在深化改革、突破传统中共同开创人才辈出、人尽其才、人尽其用的新局面，推动新时代高校人事人才工作再上新台阶。
          </div>
        </div>
      </div>

      <div class="pxContent center">
        <h4 class="descH4">新时代高校人事人才管理能力提升专题培训班（第一期）</h4>
        <span class="line"></span>
        <div style="width:1200px;margin-top:60px">
          <div class="pxListContent">
            <img v-for="(item,index) in firstList" :key="index" :src="item" alt="" class="pxImg">
          </div>
        </div>
      </div>

      <div class="pxContent center">
        <h4 class="descH4">新时代高校人事人才管理能力提升专题培训班（第二期）</h4>
        <span class="line"></span>
        <div style="width:1200px;margin-top:60px">
          <div class="pxListContent">
            <img v-for="(item,index) in secondList" :key="index" :src="item" alt="" class="pxImg">
          </div>
        </div>
      </div>

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductRsrcpxfw',
  data () {
    return {
      firstList: [
        require('@/assets/product/productRsrcpx/one_First.png'),
        require('@/assets/product/productRsrcpx/one_Two.png'),
        require('@/assets/product/productRsrcpx/one-Three.png'),
        require('@/assets/product/productRsrcpx/one-Four.png'),
        require('@/assets/product/productRsrcpx/one-five.png'),
        require('@/assets/product/productRsrcpx/one-Six.png')
      ],
      secondList: [
        require('@/assets/product/productRsrcpx/Second_one.png'),
        require('@/assets/product/productRsrcpx/Second-Two.png'),
        require('@/assets/product/productRsrcpx/Second-Three.png'),
        require('@/assets/product/productRsrcpx/Second-Four.png'),
        require('@/assets/product/productRsrcpx/Second-Five.png'),
        require('@/assets/product/productRsrcpx/Second-Six.png')
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moProductRsrcpxfw')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
.smartDTContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}

.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/product/productRsrcpx/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.ptDesc{
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.ptDescContent{
  width: 1200px;
  height: 300px;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
  margin: 40px 0;
  border-radius: 10px;
}
.ptDescLeft{
  position: relative;
  top: 0;
  left: 0;
  width: 450px;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.ptDescLeftMask{
  width: 450px;
  height: 100%;
  border-radius: 10px 0 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 48, 0.15);
}
.ptDescRigth{
  width: 750px;
  height: 100%;
  background: url('~@/assets/product/productPt/ptBj.jpg') center no-repeat;
  background-size: 100% 100%;
  border-radius: 0px 10px 10px 0px;
  padding: 38px 40px;
  text-shadow: 0px 8px 24px rgba(0,0,0,0.15);
  font-size: 18px;
  color: #222;
  font-weight: 400;
  line-height: 32px;
  font-size: 18px;
}

.pxContent{
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  padding-bottom: 20px;
}
.pxListContent{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pxImg{
  width:386px;
  margin-bottom: 20px;
}
</style>
